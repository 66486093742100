

export default {
	name: 'Btn',
	props: {
		label: String,
		disabled: {
		default: false,
		type: Boolean
		}
	},
	data()
	{
		return {
		isHover: false,
		extSvg: ''
		};
	}
};
