

import {isEmpty} from '@labor-digital/helferlein';
import Btn from '../../WebsiteComponents/Btn/Btn.vue';
import InputField from '../../WebsiteComponents/InputField/InputField.vue';

export default {
	name: 'ChatBotInput',
	components: {Btn, InputField},
	props: {
		value: null
	},
	computed: {
		emptyValue(): boolean
		{
			return isEmpty(this.value);
		}
	},
	methods: {
		onButtonClick()
		{
			if (this.emptyValue) {
				return;
			}

			this.$emit('submit');
		},
		onKeyUp(e: KeyboardEvent)
		{
			if (this.emptyValue) {
				return;
			}

			if (e.key === 'Enter') {
				this.$emit('submit');
			}
		}
	}
};
