var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"launcher-wrapper"},[_c('div',{staticClass:"launcher",class:_vm.isOpened ? 'opened' : 'closed',on:{"click":_vm.toggle}},[(_vm.isOpened)?_c('font-awesome-icon',{staticClass:"closed-icon",attrs:{"icon":[
				'fal',
				'times'
			]}}):_c('font-awesome-icon',{staticClass:"open-icon",attrs:{"icon":[
				'fal',
				'comment-lines'
			]}})],1),_vm._v(" "),_c('div',{staticClass:"chat-window",class:_vm.isOpened ? 'opened' : 'closed'},[_c('ChatWindow')],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }