<template>
	<div class="chat-window">
		<ChatBot />
	</div>
</template>
<script>
import ChatBot from '~/chatbot/ChatBot.vue';
export default {
	components: {
		ChatBot
	}
}
</script>

<style lang="scss">
@import '~@/assets/scss/common';
$chat-box-shadow-color: rgba(122, 122, 122, 0.25);

.chat-window {
	width: 370px;
	position: fixed;
	right: 25px;
	bottom: 100px;
	box-sizing: border-box;

	@include media-breakpoint-up(md) {
		box-shadow: 0 7px 20px 2px $chat-box-shadow-color;
	}

	background: #fff;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	border-radius: 10px;
	animation: fadeIn;
	animation-duration: 0.3s;
	animation-timing-function: ease-in-out;
	z-index: 9999999;

	&.closed {
		opacity: 0;
		display: none;
		bottom: 90px;
	}
}
@keyframes fadeIn {
	0% {
		display: none;
		opacity: 0;
	}
	100% {
		display: flex;
		opacity: 1;
	}
}
@include media-breakpoint-down(sm) {
	.chat-window {
		width: 100%;
		height: 100%;
		max-height: 100%;
		right: 0;
		bottom: 0;
		border-radius: 0;
		transition: 0.1s ease-in-out;
		&.closed {
			bottom: 0;
		}
	}
}
</style>
