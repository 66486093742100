
import {isEmpty, scrollToPosition} from '@labor-digital/helferlein';
import Spinner from '../../WebsiteComponents/Spinner/Spinner.vue';
import {ChatBotMessage} from '../../ChatBot.interfaces';

export default {
	name: 'ChatBotMessages',
	components: {Spinner},
	props: {
		messages: {
		type: Array,
		required: true
		} as Array<ChatBotMessage>,
		showSpinner: {
		type: Boolean,
		default: false
		}
	},
	mounted()
	{
		this.$nextTick(() => this.scrollToBottom());
	},
	methods: {
		scrollToBottom()
		{
		if (!isEmpty(this.$refs.scrollContainer)) {
			scrollToPosition(9999999, 100, this.$refs.scrollContainer);
		}
		}
	},
	watch: {
		messages()
		{
		this.scrollToBottom();
		}
	}
};
