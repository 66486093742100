<template>
	<div class="launcher-wrapper">
		<div
			class="launcher"
			@click="toggle"
			:class="isOpened ? 'opened' : 'closed'"
		>

			<font-awesome-icon
				v-if="isOpened"
				class="closed-icon"
				:icon="[
					'fal',
					'times'
				]"
			/>

			<font-awesome-icon
				v-else
				class="open-icon"
				:icon="[
					'fal',
					'comment-lines'
				]"
			/>
		</div>
		<div class="chat-window" :class="isOpened ? 'opened' : 'closed'">
			<ChatWindow />
		</div>
	</div>
</template>

<script>
import ChatWindow from './ChatWindow.vue'

export default {
	components: {
		ChatWindow
	},
	data () {
		return {
			isOpened: false
		}
	},
	methods: {
		toggle() {
			this.isOpened = !this.isOpened;
			document.body.className = !this.isOpened
		}
  	}
}
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/common';
$launcher-icon-size: 50px !default;
$launcher-icon-position: 20px !default;
$launcher-box-shadow-color: rgba(148, 149, 150, 0.2) !default;
$launcher-box-shadow-color-hover: rgba(0, 0, 0, 0.2) !default;


.launcher {
	position: fixed;
	width: $launcher-icon-size;
	height: $launcher-icon-size;
	right: $launcher-icon-position;
	bottom: $launcher-icon-position * 5;
	border-radius: 50%;
	box-shadow: none;
	transition: box-shadow 0.2s ease-in-out;
	cursor: pointer;
	background-color: $secondary;
	color: #fff;
	box-shadow: 0 0 10px #0000004d;
	z-index: 999;

	@include media-breakpoint-up(md) {
		bottom: $launcher-icon-position;
	}

	&:before {
		content: '';
		position: relative;
		display: block;
		width: $launcher-icon-size;
		height: $launcher-icon-size;
		border-radius: 50%;
		transition: box-shadow 0.2s ease-in-out;
	}
	.open-icon {
		width: $launcher-icon-size;
		height: $launcher-icon-size;
		position: fixed;
		right: $launcher-icon-position;
		bottom: $launcher-icon-position * 5;
		transition: opacity 100ms ease-in-out, transform 100ms ease-in-out;

		@include media-breakpoint-up(md) {
			bottom: $launcher-icon-position;
		}
		opacity: 1;
		padding: 10px;
		box-sizing: border-box;
	}
	.closed-icon {
		width: $launcher-icon-size;
		height: $launcher-icon-size;
		position: fixed;
		right: $launcher-icon-position;
		bottom: $launcher-icon-position * 5;
		transition: opacity 100ms ease-in-out, transform 100ms ease-in-out;

		@include media-breakpoint-up(md) {
			bottom: $launcher-icon-position;
		}
		transition: opacity 100ms ease-in-out, transform 100ms ease-in-out;
	}
	&.opened {
		.open-icon {
			transform: rotate(-90deg);
			opacity: 1;
		}
		.closed-icon {
			transform: rotate(-90deg);
			opacity: 1;
		}
		&:before {
			box-shadow: 0 0 400px 250px $launcher-box-shadow-color;
		}
	}
	&:hover {
		box-shadow: 0 0 27px 1.5px $launcher-box-shadow-color-hover;
	}
}
.closed-icon {
	padding: 10px;
	box-sizing: border-box;
	width: 30px!important;
    height: 30px!important;

}
</style>
