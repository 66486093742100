

import {isString} from '@labor-digital/helferlein';
import {ChatBotMessage, ChatBotResponse} from './ChatBot.interfaces';
import ChatBotInput from './Components/ChatBotInput/ChatBotInput.vue';
import ChatBotMessages from './Components/ChatBotMessages/ChatBotMessages.vue';

export default {
	name: 'ChatBot',
	components: {ChatBotMessages, ChatBotInput},
	data()
	{
		// @todo this should get the messages from the global store in your framework
		// const messages = asArray(this.context.store.get(AppStoreKeys.CHAT_BOT_MESSAGES, []));
		const messages = [];

		// Force initial question
		if (messages.length === 0) {
			const message = this.$t('chatBot.initialMessage').replace(
				/{{link}}/,
				'/datenschutz.html'
			);

			messages.push({
				type: 'answer',
				content: message,
				buttons: []
			});
		}

		return {
			inputValue: '',
			showSpinner: false,
			messages: messages
		};
	},
	methods: {
		onSubmit() {
			this.send();
		},
		onQuestionClick(message: string) {
			this.inputValue = message;
			this.send();
		},
		pushMessage(message: string | ChatBotResponse, type: 'question' | 'answer') {
			let buttons = [];

			if (!isString(message)) {
				buttons = message.buttons;
				message = message.text;
			}

			this.messages.push({
				type, content: message, buttons
			} as ChatBotMessage);

			// Remove messages from the top
			if (this.messages.length > 50) {
				this.messages.shift();
			}

			// Store messages for later
			// @todo this should store the messages in your global store
			// this.context.store.set(AppStoreKeys.CHAT_BOT_MESSAGES, this.messages);
		},
		send() {
			this.showSpinner = true;
			const question = this.inputValue;
			this.pushMessage(question, 'question');
			this.inputValue = '';
			this.$axios.setHeader('Content-Type', 'application/json')
			this.$axios.post(`chat-bot/answer/`, {
				question
			})
			.then(resp => {
					 this.pushMessage(
						resp.data.answer ?? this.$t('chatBot.error.response'),
						'answer'
               		);
				}
			)
			.catch(err => {
				this.pushMessage(this.$t('chatBot.error.network'), 'answer');
			})
			.then(() => {
				this.showSpinner = false;
			});
		}
	}
};
