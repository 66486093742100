
import Spinner from '../../WebsiteComponents/Spinner/Spinner.vue';

export default {
	name: 'InputField',
	components: { Spinner },
	props: {
		label: {
			default: ''
		},
		value: [String, Number],
		placeholder: {
			type: String
		},
		showSpinner: {
			type: Boolean,
			default: false
		}
	}
};
